@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
}

body {
  overflow-x: hidden;
}

.main-div {
  width: 100vw;
  display: flex;
  padding: 50px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ECF0FF;
}


@media (max-width: 767px) {
  .main-div {
    padding: 10px ;
  }
}